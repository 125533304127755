<template>
  <div>
    <div class="header">
      <van-nav-bar
        :title="$t('平台简介')"
        left-arrow
        @click-left="onClickLeft"
      >
      </van-nav-bar>
    </div>
    <div class="maincontent">
      <van-tabs color="#004ea3" @click="getdetail" v-model="activeid">
        <van-tab :title="lang === 'zh' ? item.name : item[`name_${lang}`]" v-for="item in types" :key="item.id">
          <van-row class="agreement" v-if="types[activeid].detail">
            <h1>{{ lang === 'zh' ? types[activeid].detail.title : types[activeid].detail[`title_${lang}`] }}</h1>
            <p class="text"></p>
            <p>{{ lang === 'zh' ? types[activeid].detail.content : types[activeid].detail[`content_${lang}`] }}</p>
          </van-row>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lang: localStorage.getItem('lang') || 'zh',
      list: [],
      types: [
            {
          "id": "4",
          "info_id":27,
          "name": "平臺簡介",
          "name_ru": "",
          "name_jp": "プラットフォームの概要",
          "name_fr": "",
          "name_es": "",
          "name_en": "Platform Introduction",
          "name_spa": "Introducción a la plataforma",
          "action_user": "admin",
          "action_user_id": "1",
          "createtime": "1649223067",
          "detail":false
        },
        {
          "id": "5",
          "info_id":26,
          "name": "監管條款",
          "name_ru": "",
          "name_jp": "規制条項",
          "name_fr": "",
          "name_es": "",
          "name_en": "Regulatory provisions",
          "name_spa": "Disposiciones regulatorias",
          "action_user": "admin",
          "action_user_id": "1",
          "createtime": "1649223067",
          "detail":false
        }
      ],
      activeid: 0,
    }
  },
  created() {
    this.getdetail()
  },
  methods: {
    async getdetail() {
      if(!this.types[this.activeid].detail){
        const { data } = await this.$http.get(
          '/home/home/coursedetail/id/' + this.types[this.activeid].info_id
        )
        if (data) {
          if (data.code === 200) {
            this.types[this.activeid].detail = data.data
          }
        }
      }
    },
    onClickLeft() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="less" scoped>
.maincontent {
  padding: 70px 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 0.93333rem;
  background: #f7f7f7;
  height: 100vh;
  /deep/.van-tabs__nav--line {
    box-sizing: initial;
    height: 100%;
    padding-bottom: 15px;
  }
  /deep/.van-tab {
    font-size: 1rem;
    background: #f7f7f7;
  }
  /deep/.van-tab--active {
    color: #004ea3;
  }
  /deep/.van-tabs__content,
  /deep/.van-tabs__nav--card .van-tab.van-tab--active {
    background-color: #fff;
    color: #000;
  }
  .van-cell__title {
    text-align: left;
  }
}
.agreement {
    width: 100%;
    color: #000;
    overflow: hidden;
    h1 {
      font-size: 1.75rem;
      color: #2c3e50;
      text-align: left;
      margin: 2.66667rem 0.66667rem 0.53333rem;
      padding-top: 1.33333rem;
      border-top: 0.02667rem solid #a3a3a3;
    }
    p {
      text-indent: 1.54667rem;
      text-align: left;
      margin: 0.66667rem;
      font-size: 0.93333rem;
      line-height: 1.3rem;
    }
  }
</style>
